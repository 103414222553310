<template>
  <div>
    <v-dialog v-if="!isIos" v-model="androidDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="word-break">
          Agregar Fiseapp a pantalla de inicio
        </v-card-title>
        <v-card-text>Fiseapp</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="androidDialog = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="installPwa">
            Agregar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="iosDialog"
      persistent
      max-width="500"
      content-class="my-custom-dialog"
    >
      <v-card>
        <v-card-title class="word-break">
          Instalar FISE APP
          <div class="text-right custom-icon">
            <v-icon @click="iosDialog = false"> mdi-close </v-icon>
          </div>
        </v-card-title>
        <v-card-text>
          <p>
            Puedes agregar esta aplicación a la página de inicio de tu
            dispositivo para que accedas a ella fácil y rápido.
          </p>
          <p>
            1. Toca el ícono
            <img
              class="ios-icon"
              src="../../public/img/icons/action-share-icon-ios.svg"
            />
            en la parte inferior de la pantalla
          </p>
          <p>2. Selecciona la opción "Agregar a inicio"</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.word-break {
  word-break: break-word;
}
</style>

<script>
export default {
  name: "prompt-pwa",
  props: {
    isIos: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      deferredPrompt: null,
      androidDialog: false,
      iosDialog: false,
    };
  },
  methods: {
    installPwa() {
      if (!this.deferredPrompt) return;
      this.deferredPrompt.prompt();
      this.deferredPrompt = null;
      this.androidDialog = false;
    },
  },
  created() {
    let $this = this;
    if (!this.isIos) {
      window.onload = () => {
        window.addEventListener("beforeinstallprompt", (e) => {
          e.preventDefault();
          $this.deferredPrompt = e;
          $this.androidDialog = true;
        });
      };
    } else {
      $this.iosDialog = true;
    }
  },
};
</script>
<style scoped>
>>> .my-custom-dialog {
  align-self: flex-end;
  margin-bottom: 5px;
}
.custom-icon {
  flex: auto;
  margin-bottom: 5px;
}
.ios-icon {
  height: 30px;
  width: 30px;
}
</style>
